<template>
	<assistant-index type="store" :needLocation="false" :store="current_store" v-if="isDefined" />
	<layouts-loading text="iniciando" v-else />
</template>

<script>
	import composableStore from '@/composables/store';

	export default {
		setup() {
			const { isDefined, current_store } = composableStore();

			return { isDefined, current_store };
		}
	}
</script>